@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
/* A Modern CSS Reset */
*,
*::before,
*::after {
  box-sizing: border-box;
}
ul[class],
ol[class] {
  padding: 0;
}
body,
h1,
h2,
h3,
h4,
p,
ul[class],
ol[class],
figure,
blockquote,
dl,
dd {
  margin: 0;
}
a {
  text-decoration: none;
}
a:hover {
  color: white;
  text-decoration: none;
  cursor: pointer;
}
body {
  min-height: 100vh;
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
}
ul[class],
ol[class] {
  list-style: none;
}
a:not([class]) {
  -webkit-text-decoration-skip: ink;
          text-decoration-skip-ink: auto;
}
img {
  max-width: 100%;
  display: block;
}
article > * + * {
  margin-top: 1em;
}
input,
button,
textarea,
select {
  font: inherit;
}
img:not([alt]) {
  -webkit-filter: blur(10px);
          filter: blur(10px);
}
@media (prefers-reduced-motion: reduce) {
  * {
    -webkit-animation-duration: 0.01ms !important;
            animation-duration: 0.01ms !important;
    -webkit-animation-iteration-count: 1 !important;
            animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

*:focus {
  outline: none;
}

button:focus {
  outline: 0;
}

input[type="number"] {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

/* ----------------------------------------------------------------------------------------------------
Super Form Reset
A couple of things to watch out for:
- IE8: If a text input doesn't have padding on all sides or none the text won't be centered.
- The default border sizes on text inputs in all UAs seem to be slightly different. You're better off using custom borders.
- You NEED to set the font-size and family on all form elements
- Search inputs need to have their appearance reset and the box-sizing set to content-box to match other UAs
- You can style the upload button in webkit using ::-webkit-file-upload-button
- ::-webkit-file-upload-button selectors can't be used in the same selector as normal ones. FF and IE freak out.
- IE: You don't need to fake inline-block with labels and form controls in IE. They function as inline-block.
- By turning off ::-webkit-search-decoration, it removes the extra whitespace on the left on search inputs
----------------------------------------------------------------------------------------------------*/

input,
label,
select,
button,
textarea {
  margin: 0;
  border: 0;
  padding: 0;
  display: inline-block;
  vertical-align: middle;
  white-space: normal;
  background: none;
  line-height: 1;

  /* Browsers have different default form fonts */
  font-size: 13px;
  font-family: Arial;
}

/* Remove the stupid outer glow in Webkit */
input:focus {
  outline: 0;
}

/* Box Sizing Reset
-----------------------------------------------*/

/* All of our custom controls should be what we expect them to be */
input,
textarea {
  box-sizing: content-box;
}

/* These elements are usually rendered a certain way by the browser */
button,
input[type="reset"],
input[type="button"],
input[type="submit"],
input[type="checkbox"],
input[type="radio"],
select {
  box-sizing: border-box;
}

/* Text Inputs
-----------------------------------------------*/

input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="email"],
input[type="month"],
input[type="number"],
input[type="password"],
input[type="range"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="time"],
input[type="url"],
input[type="week"] {
}

/* Button Controls
-----------------------------------------------*/

input[type="checkbox"],
input[type="radio"] {
  width: 13px;
  height: 13px;
}

/* File Uploads
-----------------------------------------------*/

input[type="file"] {
}

/* Search Input
-----------------------------------------------*/

/* Make webkit render the search input like a normal text field */
input[type="search"] {
  -webkit-appearance: textfield;
  -webkit-box-sizing: content-box;
}

/* Turn off the recent search for webkit. It adds about 15px padding on the left */
::-webkit-search-decoration {
  display: none;
}

/* Buttons
-----------------------------------------------*/

button,
input[type="reset"],
input[type="button"],
input[type="submit"] {
  /* Fix IE7 display bug */
  overflow: visible;
  width: auto;
}

/* IE8 and FF freak out if this rule is within another selector */
::-webkit-file-upload-button {
  padding: 0;
  border: 0;
  background: none;
}

/* Textarea
-----------------------------------------------*/

textarea {
  /* Move the label to the top */
  vertical-align: top;

  /* Turn off scroll bars in IE unless needed */
  overflow: auto;
}

/* Selects
-----------------------------------------------*/

select {
}

select[multiple] {
  /* Move the label to the top */
  vertical-align: top;
}

.rainbow {
  background: linear-gradient(
    124deg,
    #ff2400,
    #e81d1d,
    #e8b71d,
    #e3e81d,
    #1de840,
    #1ddde8,
    #2b1de8,
    #dd00f3,
    #dd00f3
  );
  background-size: 1800% 1800%;

  -webkit-animation: rainbow 18s ease infinite;
  -z-animation: rainbow 18s ease infinite;
  animation: rainbow 18s ease infinite;

  text-align: center;
  color: #fff;
  font-size: 100px;
  padding-top: 130px;
}

@-webkit-keyframes rainbow {
  0% {
    background-position: 0% 82%;
  }
  50% {
    background-position: 100% 19%;
  }
  100% {
    background-position: 0% 82%;
  }
}

@keyframes rainbow {
  0% {
    background-position: 0% 82%;
  }
  50% {
    background-position: 100% 19%;
  }
  100% {
    background-position: 0% 82%;
  }
}

@font-face {
  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 300;
  src: url(/static/media/NotoSansKR-DemiLight.4028af33.otf) format("opentype");
}
@font-face {
  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/NotoSansKR-Regular.c3f8d68f.otf) format("opentype");
}
@font-face {
  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 500;
  src: url(/static/media/NotoSansKR-Medium.e68e938a.otf) format("opentype");
}
@font-face {
  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/NotoSansKR-Bold.38c81d5e.otf) format("opentype");
}

/* Slider */
/* Slideshow container */
#slide-container {
  width: 100%;
  height: auto;
  position: relative;
}
/* First element to be in block mode for responsiveness */
#slide-first-element,
#slide-element-2,
#slide-element-3 {
  display: flex;
  justify-content: center;
  align-items: baseline;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
#slide-element-2 {
  opacity: 0;
}
/* Style images */
.slide-image {
  max-width: 1920px;
}
@media screen and (min-width: 1920px) {
  .slide-image {
    max-width: 100%;
    width: 100%;
  }
}
/* Style text */
.slide-text {
  position: absolute;
  bottom: 10px;
  background-color: #0042b1bb;
  color: white;
  width: 100%;
  text-align: center;
  font-size: 1.5rem;
}

#slide-first-element {
  animation: fade-1 infinite;
  -webkit-animation: fade-1 infinite;
  animation-duration: 8s;
  -webkit-animation-duration: 8s;
}
#slide-element-2 {
  animation: fade-2 infinite;
  -webkit-animation: fade-2 infinite;
  animation-duration: 8s;
  -webkit-animation-duration: 8s;
}

#slide-element-3 {
  animation: fade-3 infinite;
  -webkit-animation: fade-3 infinite;
  animation-duration: 8s;
  -webkit-animation-duration: 8s;
}

@-webkit-keyframes fade-1 {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  40% {
    opacity: 1;
  }
  60% {
    opacity: 0;
  }
  80% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fade-1 {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  40% {
    opacity: 1;
  }
  60% {
    opacity: 0;
  }
  80% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes fade-2 {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 0;
  }
  40% {
    opacity: 0;
  }
  60% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fade-2 {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 0;
  }
  40% {
    opacity: 0;
  }
  60% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes fade-3 {
  0% {
    opacity: 1;
  }
  20% {
    opacity: 0;
  }
  40% {
    opacity: 0;
  }
  60% {
    opacity: 0;
  }
  75% {
    opacity: 0;
  }
  90% {
    opacity: 1;
  }
}
@keyframes fade-3 {
  0% {
    opacity: 1;
  }
  20% {
    opacity: 0;
  }
  40% {
    opacity: 0;
  }
  60% {
    opacity: 0;
  }
  75% {
    opacity: 0;
  }
  90% {
    opacity: 1;
  }
}

.slick-dots li {
  width: 26px !important;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: -0.2px;
  font-weight: 500;
  color: #8a8a8a;
}

ul.slick-dots li.slick-active div.slick__custom__dots {
  text-decoration: underline;
  text-decoration-thickness: 4px;
  -webkit-text-decoration-style: solid;
          text-decoration-style: solid;
  text-underline-offset: 4px;
  -webkit-text-decoration-color: #4d87ff;
          text-decoration-color: #4d87ff;
  color: #4d87ff;
}
.slick-prev:before,
.slick-next:before {
  display: none;
}

