/* Slider */
/* Slideshow container */
#slide-container {
  width: 100%;
  height: auto;
  position: relative;
}
/* First element to be in block mode for responsiveness */
#slide-first-element,
#slide-element-2,
#slide-element-3 {
  display: flex;
  justify-content: center;
  align-items: baseline;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
#slide-element-2 {
  opacity: 0;
}
/* Style images */
.slide-image {
  max-width: 1920px;
}
@media screen and (min-width: 1920px) {
  .slide-image {
    max-width: 100%;
    width: 100%;
  }
}
/* Style text */
.slide-text {
  position: absolute;
  bottom: 10px;
  background-color: #0042b1bb;
  color: white;
  width: 100%;
  text-align: center;
  font-size: 1.5rem;
}

#slide-first-element {
  animation: fade-1 infinite;
  -webkit-animation: fade-1 infinite;
  animation-duration: 8s;
  -webkit-animation-duration: 8s;
}
#slide-element-2 {
  animation: fade-2 infinite;
  -webkit-animation: fade-2 infinite;
  animation-duration: 8s;
  -webkit-animation-duration: 8s;
}

#slide-element-3 {
  animation: fade-3 infinite;
  -webkit-animation: fade-3 infinite;
  animation-duration: 8s;
  -webkit-animation-duration: 8s;
}

@keyframes fade-1 {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  40% {
    opacity: 1;
  }
  60% {
    opacity: 0;
  }
  80% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fade-2 {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 0;
  }
  40% {
    opacity: 0;
  }
  60% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fade-3 {
  0% {
    opacity: 1;
  }
  20% {
    opacity: 0;
  }
  40% {
    opacity: 0;
  }
  60% {
    opacity: 0;
  }
  75% {
    opacity: 0;
  }
  90% {
    opacity: 1;
  }
}
