.slick-dots li {
  width: 26px !important;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: -0.2px;
  font-weight: 500;
  color: #8a8a8a;
}

ul.slick-dots li.slick-active div.slick__custom__dots {
  text-decoration: underline;
  text-decoration-thickness: 4px;
  text-decoration-style: solid;
  text-underline-offset: 4px;
  text-decoration-color: #4d87ff;
  color: #4d87ff;
}
.slick-prev:before,
.slick-next:before {
  display: none;
}
